import ReactDOM from 'react-dom';
import { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import outy from 'outy';

import { LogoIcon, MenuIcon, CloseIcon } from 'components/Icons';
import s from './s.module.css';

const root = document.getElementById('root');
const rootModal = document.getElementById('root-modal');

const navLinks = [
  {
    children: 'Polls',
    to: '/',
    exact: true,
  },
  {
    children: 'About',
    to: '/about',
  },
  {
    children: 'Fips',
    to: 'https://github.com/filecoin-project/FIPs',
    external: true,
  },
  {
    children: 'Filecoin',
    to: 'https://filecoin.io/',
    external: true,
  },
];

export const Header = () => {
  const [show, setShow] = useState(false);
  const headerRef = useRef(null);

  const handlerOpen = () => {
    setShow(true);
  };

  const handlerClose = () => {
    setShow(false);
  };

  useEffect(() => {
    let outsideTap;

    if (show) {
      if (headerRef.current) {
        outsideTap = outy(
          headerRef.current,
          ['click', 'touchend'],
          handlerClose
        );
      }
      if (root) {
        root.setAttribute('inert', 'true');
      }
    }

    return () => {
      if (outsideTap) {
        outsideTap.remove();
      }
      if (root) {
        root.removeAttribute('inert');
      }
    };
  }, [show]);

  useEffect(() => {
    const handler = () => {
      if (show && window.innerWidth > 1023) {
        root.removeAttribute('inert');
        setShow(false);
      }
    };

    handler();
    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [show]);

  const getLinkNode = (link) => {
    const { children, to, external, exact } = link;

    if (external) {
      return (
        <a
          key={to}
          href={to}
          className={s.link}
          onClick={show ? handlerClose : undefined}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      );
    }

    return (
      <NavLink
        key={to}
        to={to}
        className={s.link}
        activeClassName={s.active}
        onClick={show ? handlerClose : undefined}
        exact={exact}
      >
        {children}
      </NavLink>
    );
  };

  return (
    <>
      <header className={s.header}>
        <div className={s.container}>
          <div className={s.mobileRow}>
            <button
              type="button"
              className={s.menuButton}
              onClick={handlerOpen}
            >
              <MenuIcon />
            </button>
            <NavLink to="/" className={s.logoLink}>
              <LogoIcon />
              <span>FILPoll</span>
            </NavLink>
          </div>
          <nav className={s.nav}>
            {navLinks.map((link) => getLinkNode(link))}
          </nav>
        </div>
        <p className={s.subtitle}>A polling tool for the Filecoin community</p>
      </header>

      {show &&
        ReactDOM.createPortal(
          <div className={s.overlay}>
            <header className={s.header} ref={headerRef}>
              <div className={s.container}>
                <div className={s.mobileRow}>
                  <button
                    type="button"
                    className={s.menuButton}
                    onClick={handlerClose}
                  >
                    <CloseIcon />
                  </button>
                  <NavLink to="/" className={s.logoLink} onClick={handlerClose}>
                    <LogoIcon />
                    <span>FILPoll</span>
                  </NavLink>
                </div>
                <nav className={s.nav}>
                  {navLinks.map((link) => getLinkNode(link))}
                </nav>
              </div>
            </header>
          </div>,
          rootModal
        )}
    </>
  );
};
