import ReactMarkdown from 'react-markdown';
import cn from 'classnames';

import s from './s.module.css';

const markdownText = `
## About FilPoll

### Overview

FilPoll is a Filecoin community polling tool for gauging community sentiment on [Filecoin Improvement Proposals](https://github.com/filecoin-project/fips#readme).

It can tally poll results based on a user's FIL token holdings, a storage providers power, or the addresses of verified core developers, app developers, or ecosystem collaborator organizations, or the amount stored by storage clients. All poll results are saved to Textile Buckets on IPFS so they can be audited later on.

Note that this is a community polling tool to survey what the community thinks, not an official community voting tool for FIPs.

### How to Participate

You can submit your opinion on a poll using any mainnet account by using the Lotus CLI. (Instructions on how to set up a Lotus Filecoin mainnet node are available [**here**](https://lotus.filecoin.io/lotus/install/prerequisites/).)

You do ***not*** need to have a fully synced mainnet node to sign a message.

1. After selecting a poll option and clicking 'Submit your vote'
2. Enter your mainnet address in the new window then
3. Copy the Lotus sign command to your CLI and hit enter.
4. Finally, paste the output from the Lotus CLI back into the Filpoll.io window and select ‘Confirm Vote’.

Note: A signing UI will also be added to the Glif.io web wallet soon to support Filecoin x Ledger hardware wallet users who do not want to install a node via CLI.

### How are Polls tallied?
Each poll shows the block height used for power and token balances. A snapshot of the mainnet chain at this height is used to evaluate the power and balance of token holders and miners who submit votes. Developer categories are tallied by developer, Ecosystem collaborators by organization and Storage clients by amount stored in storage deals.

### Why isn't there an aggregate tally across all categories?
Each constituent category is kept separate because they use different units (FIL, Power, Developers, Organizations, Storage, etc.). It doesn't make sense to tally votes across all categories if there are different units.

### Do all FIPs have community polls?
No, specific FIPs are flagged as needing a poll to survey the community for opinions. If there's an FIP that you'd like to see as a community poll, reach out (see our contact info below). A list of all FIPs that have polls can be found in the [filecoin-project/community Github repo’s polls directory](https://github.com/filecoin-project/community/tree/master/polls).

### Who created this tool?
Filpoll.io is a project designed and sponsored by Protocol Labs and built by Digital Mob, a Filecoin ecosystem collaborator.

### Is Filpoll.io open source?
Yes, you can find the source code at the following repos:
* [https://github.com/Digital-MOB-Filecoin/polling-api](https://github.com/Digital-MOB-Filecoin/polling-api)
* [https://github.com/Digital-MOB-Filecoin/polling-webapp](https://github.com/Digital-MOB-Filecoin/polling-webapp)

### Questions or Comments?
Visit the [#fil-filpoll](https://filecoinproject.slack.com/archives/C01RP88PFSL) channel in [Filecoin Slack](https://filecoin.io/slack).
`;

export default function AboutPage() {
  return (
    <ReactMarkdown
      children={markdownText}
      className={cn('markdownContainer', s.container)}
      linkTarget="_blank"
    />
  );
}
