import BigNumber from 'bignumber.js';

export function getFormattedFIL(attoFil) {
  let attoBN = new BigNumber(attoFil);

  if (attoBN.isNaN()) {
    return { unit: '', value: '' };
  }

  if (attoBN.isZero()) {
    return { unit: 'FIL', value: 0 };
  }

  if (attoBN.isGreaterThanOrEqualTo(BigNumber(10).pow(14))) {
    return {
      unit: 'FIL',
      value: attoBN.shiftedBy(-18).decimalPlaces(4).toFixed(),
    };
  }

  if (attoBN.isGreaterThanOrEqualTo(BigNumber(10).pow(5))) {
    return {
      unit: 'nanoFIL',
      value: attoBN.shiftedBy(-9).decimalPlaces(4).toFixed(),
    };
  }

  return { unit: 'attoFIL', value: attoBN.toFixed() };
}
