import React from 'react';
import { nanoid } from 'nanoid';
import cn from 'classnames';

import { CopyIcon } from 'components/Icons';
import s from './s.module.css';

export function Input({ children, id, className, value, ...rest }) {
  const inputId = React.useMemo(() => id ?? nanoid(), [id]);

  return (
    <label
      className={cn(s.label, { [s.hasValue]: !!value }, className)}
      htmlFor={inputId}
    >
      <input
        className={cn(s.input, { [s.hasValue]: !!value })}
        id={inputId}
        value={value}
        {...rest}
      />
      <div className={cn(s.text, { [s.hasValue]: !!value })}>{children}</div>
    </label>
  );
}

export function SignInput({ children, id, className, value, ...rest }) {
  const inputId = React.useMemo(() => id ?? nanoid(), [id]);
  const textareaRef = React.useRef();

  const copyToClipboard = () => {
    textareaRef.current.select();
    document.execCommand('copy');
  };

  return (
    <label className={cn(s.signLabel, className)} htmlFor={inputId}>
      <div className={s.signText}>Lotus sign command</div>
      <textarea
        className={s.textarea}
        id={inputId}
        value={value}
        ref={textareaRef}
        {...rest}
      />
      <p className={s.note}>
        Run this command in your lotus client to sign the voting message. Copy
        the output of the command in the field below
      </p>
      <button type="button" onClick={copyToClipboard} className={s.copyButton}>
        <CopyIcon />
      </button>
    </label>
  );
}

export function Textarea({ children, id, className, value, ...rest }) {
  const inputId = React.useMemo(() => id ?? nanoid(), [id]);

  return (
    <label
      className={cn(s.label, { [s.hasValue]: !!value }, className)}
      htmlFor={inputId}
    >
      <textarea
        className={cn(s.textarea, { [s.hasValue]: !!value })}
        id={inputId}
        value={value}
        {...rest}
      />
      <div className={cn(s.text, s.textareaText, { [s.hasValue]: !!value })}>
        {children}
      </div>
    </label>
  );
}
