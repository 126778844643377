export const shortcutString = (value = '', characters = 4) => {
  if (!value) {
    return '';
  }

  if (value.length - characters * 2 < 2) {
    return value;
  }

  return `${value.slice(0, characters)}...${value.slice(-characters)}`;
};
