import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import { api } from 'utils/api';
import { getFormattedUTCDate } from 'utils/dates';
import { getFormattedFIL } from 'utils/numbers';
import { convertBytesToIEC } from 'utils/bytes';
import { LeftArrowIcon, DropdownArrowIcon } from 'components/Icons';
import { Stats } from 'components/Stats';
import { Status } from 'components/Status';
import { VoteModal } from 'components/VoteModal';
import { Spinner } from 'components/Spinner';
import { CopyButton } from 'components/CopyButton';
import { config } from 'config';

import s from './s.module.css';

const defaultIssueState = {
  data: null,
  loading: false,
  loaded: false,
};

const formatValue = (value, unit) => {
  switch (unit) {
    case '':
      return { value, unit: '' };
    case 'fil':
      return getFormattedFIL(value);
    case 'kb':
      return convertBytesToIEC(value, 2);
    default:
      return { value, unit };
  }
};

export default function PollPage() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showDetails, setShowDetails] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [issue, setIssue] = useState(defaultIssueState);
  const [userVotes, setUserVotes] = useState([]);
  const { id } = useParams();

  const onSuccessfulVote = (data) => {
    const userStoredVotes = window.localStorage.getItem(config.storageName);
    let newStoredVotes;

    if (userStoredVotes && userStoredVotes.version === config.storageVersion)
      newStoredVotes = {
        ...userVotes.votes,
        [id]: data.castVotes,
      };
    else {
      newStoredVotes = { [id]: data.castVotes };
    }
    window.localStorage.setItem(
      config.storageName,
      JSON.stringify({ version: config.storageVersion, votes: newStoredVotes })
    );

    setUserVotes(data.castVotes);
    setIssue((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        voteResults: data.voteResults,
      },
    }));
  };

  useEffect(() => {
    const userStoredVotes = window.localStorage.getItem(config.storageName);

    if (userStoredVotes) {
      const parsed = JSON.parse(userStoredVotes);
      if (parsed?.version === config.storageVersion) {
        setUserVotes(parsed?.votes?.[id] || []);
      }
    }
  }, []);

  useEffect(() => {
    setIssue({
      ...defaultIssueState,
      loading: true,
    });

    api(`/polls/${id}`)
      .then((data) => {
        setIssue({
          data,
          loading: false,
          loaded: true,
        });
      })
      .catch((err) => {
        console.error(err);
        setIssue({
          ...defaultIssueState,
          loaded: true,
        });
      });
  }, [id]);

  useEffect(() => {
    const handler = () => {
      if (!showDetails && window.innerWidth > 1023) {
        setShowDetails(true);
      }
    };

    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [showDetails]);

  if (!issue.loaded) {
    return (
      <div className={s.container} style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    );
  }

  if (!issue.data) {
    return <div className={s.container}>Poll Not Found</div>;
  }

  const {
    constituentGroups,
    description,
    end,
    issueLink,
    name,
    options,
    snapshotHeight,
    start,
    status,
    textileBucketRootKey,
    userProfileName,
    voteResults,
  } = issue.data;

  const handlerToggleDetails = () => {
    setShowDetails((prevState) => !prevState);
  };

  return (
    <>
      <div className={s.container}>
        <div>
          <NavLink to="/" className={s.backLink}>
            <LeftArrowIcon />
            <span>Back to Polls</span>
          </NavLink>
        </div>
        <h2 className={cn('h2', s.title)}>{name}</h2>
        <div className={s.details}>
          <div className={cn('block', s.voteContainer)}>
            <ReactMarkdown
              plugins={[gfm]}
              children={description}
              className="markdownContainer"
            />
            {userVotes.length ? (
              <div className={s.userVotesWrap}>
                <hr className={s.hr} />
                <h2 className={cn('h3', s.userVotesTitle)}>
                  Your current votes
                </h2>
                <div className={s.userVotesContainer}>
                  {userVotes.map(
                    ({ value, unit, option, constituentGroup }, idx) => {
                      const formattedValue = formatValue(value, unit);

                      return (
                        <div key={idx} className={s.userVoteBlock}>
                          <div
                            className={s.userVoteTitle}
                            data-unit={formattedValue.unit}
                          >
                            {formattedValue.value}
                          </div>
                          <div className={s.userVoteDescription}>
                            <span className={s.option}>{option}</span> vote as a{' '}
                            {constituentGroup}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}
            {status === 'open' && options.length ? (
              <form>
                <hr className={s.hr} />
                <div className={s.voteWrap}>
                  {options.map((label) => (
                    <label
                      key={label.id}
                      htmlFor={`label${label.id}`}
                      className={cn(s.voteLabel, {
                        [s.active]: selectedOption?.id === label.id,
                      })}
                    >
                      <input
                        type="radio"
                        name="vote"
                        id={`label${label.id}`}
                        value={label.encodedMessageToSign}
                        checked={selectedOption?.id === label.id}
                        onChange={() => setSelectedOption(label)}
                      />
                      <span className={s.voteIcon} />
                      <span className={s.voteName}>{label.name}</span>
                    </label>
                  ))}
                </div>
                <button
                  className={cn('button', s.voteButton)}
                  type="button"
                  onClick={() => setShowModal(true)}
                  disabled={!selectedOption}
                >
                  Submit your vote
                </button>
              </form>
            ) : null}
          </div>

          <dl className={cn('block', s.dl)}>
            <h3 className={cn('h3', s.dlTitle)}>
              <span>Poll details</span>
              <button
                type="button"
                onClick={handlerToggleDetails}
                className={cn(s.detailsButton, { [s.active]: showDetails })}
              >
                <DropdownArrowIcon />
              </button>
            </h3>
            {showDetails && (
              <>
                <div className={s.dlRow}>
                  <dt>Status</dt>
                  <dd>
                    <Status status={status} />
                  </dd>
                </div>
                <div className={s.dlRow}>
                  <dt>Start Time</dt>
                  <dd>{getFormattedUTCDate(start)}</dd>
                </div>
                <div className={s.dlRow}>
                  <dt>End Time</dt>
                  <dd>{getFormattedUTCDate(end)}</dd>
                </div>
                <div className={s.dlRow}>
                  <dt>Author</dt>
                  <dd>{userProfileName}</dd>
                </div>
                <div className={s.dlRow}>
                  <dt>Discussion</dt>
                  <dd>
                    <a
                      href={issueLink}
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GitHub Link
                    </a>
                  </dd>
                </div>
                <div className={s.dlRow}>
                  <dt>Height for Pow/Bal</dt>
                  <dd>{snapshotHeight ? `#${snapshotHeight}` : '-'}</dd>
                </div>
                <div className={s.dlRow}>
                  <dt>IPNS hash</dt>
                  <dd>
                    <CopyButton string={textileBucketRootKey} />
                  </dd>
                </div>
              </>
            )}
          </dl>
        </div>

        {constituentGroups && constituentGroups.length ? (
          <>
            {id === '16' ? (
              <>
                <h2 className={cn('h2', s.pollTitle)}>
                  Processed poll results:
                  <div style={{ marginTop: 12 }}>
                    <a
                      href="https://observablehq.com/@vkalghatgi/fip0036-poll-tracking-dashboard"
                      className="link"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ fontSize: 18 }}
                    >
                      FIP0036 Poll Tracking Dashboard
                    </a>
                    <br />
                    <a
                      href="https://api.filpoll.io/api/polls/16/view-votes-simple"
                      className="link"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ fontSize: 18 }}
                    >
                      Voter list
                    </a>
                  </div>
                </h2>

                <h2 className={cn('h2', s.pollTitle)}>
                  Preliminary poll results
                </h2>
              </>
            ) : (
              <h2 className={cn('h2', s.pollTitle)}>Poll results</h2>
            )}
            <div className={s.votesWrap}>
              {constituentGroups.map((constituentGroup) => (
                <Stats
                  key={constituentGroup.identifier}
                  values={voteResults?.[constituentGroup.id]}
                  options={options}
                  constituentGroup={constituentGroup}
                  pollId={id}
                  className={s.stats}
                />
              ))}
            </div>
          </>
        ) : null}
      </div>
      <VoteModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={onSuccessfulVote}
        option={selectedOption}
        id={id}
      />
    </>
  );
}
