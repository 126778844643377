import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import {
  getTimeInterval,
  getRelativeTimeInterval,
  getFormattedUTCDate,
} from 'utils/dates';
import { api } from 'utils/api';

import { Spinner } from 'components/Spinner';
import { ClockIcon } from 'components/Icons';
import s from './s.module.css';

const defaultPollsState = {
  data: [],
  loading: false,
  loaded: false,
};

const PollNode = ({ poll }) => {
  const [wrapNodeType, setWrapNodeType] = useState('a');
  const { id, name, start, end, description, status } = poll;

  const Wrap = ({ children }) => {
    if (wrapNodeType === 'a') {
      return (
        <Link className={cn('block', s.block)} to={`/poll/${id}`}>
          {children}
        </Link>
      );
    }
    return <div className={cn('block', s.block)}>{children}</div>;
  };
  const ViewDetails = () => {
    if (wrapNodeType === 'div') {
      return (
        <Link to={`/poll/${id}`} className={s.link}>
          View Details
        </Link>
      );
    }
    return null;
  };

  const formattedTime =
    status === 'open'
      ? `${getTimeInterval(end)} remaining`
      : `Voting ended ${getRelativeTimeInterval(end)}`;

  useEffect(() => {
    const handler = () => {
      setWrapNodeType(window.innerWidth > 1023 ? 'div' : 'a');
    };

    handler();
    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, []);

  return (
    <Wrap>
      <div className={s.pollWrap}>
        <h3 className={s.pollName}>{name}</h3>
        <ReactMarkdown
          plugins={[gfm]}
          children={description}
          allowedTypes={['text', 'strong']}
          unwrapDisallowed
          className={cn(s.pollDescription, 'markdownContainer')}
        />
        <dl className={s.dl}>
          <div className={s.dlRow}>
            <dt>Start Time</dt>
            <dd>{getFormattedUTCDate(start)}</dd>
          </div>
          <div className={s.dlRow}>
            <dt>End Time</dt>
            <dd>{getFormattedUTCDate(end)}</dd>
          </div>
        </dl>
        <div className={s.pollMeta}>
          <span>#{id}</span>
          <span className={s.separator} />
          <time className={s.timestamp}>
            <ClockIcon
              style={{
                color:
                  status === 'open'
                    ? 'var(--color-forest-green)'
                    : 'var(--color-gainsboro)',
              }}
            />
            <span>{formattedTime}</span>
          </time>
        </div>
      </div>
      <ViewDetails />
    </Wrap>
  );
};

export default function HomePage() {
  const [polls, setPolls] = useState(defaultPollsState);

  useEffect(() => {
    setPolls({
      ...defaultPollsState,
      loading: true,
    });

    api('/polls/list')
      .then((data) => {
        setPolls({
          data,
          loading: false,
          loaded: true,
        });
      })
      .catch((err) => {
        console.error(err);
        setPolls({
          ...defaultPollsState,
          loaded: true,
        });
      });
  }, []);

  const [open, closed] = polls.data.reduce(
    (acc, poll) => {
      if (poll.status === 'open') {
        return [acc[0].concat(poll), acc[1]];
      }
      if (poll.status === 'closed') {
        return [acc[0], acc[1].concat(poll)];
      }
      return acc;
    },
    [[], []]
  );

  return (
    <div className={s.container}>
      <section className={s.section}>
        <h2 className={cn('h2', s.title)}>Open polls ({open.length})</h2>
        {polls.loading ? (
          <div style={{ textAlign: 'center' }}>
            <Spinner />
          </div>
        ) : (
          open.map((poll) => <PollNode key={poll.id} poll={poll} />)
        )}
      </section>

      <section className={s.section}>
        <h2 className={cn('h2', s.title)}>Closed polls ({closed.length})</h2>
        {polls.loading ? (
          <div style={{ textAlign: 'center' }}>
            <Spinner />
          </div>
        ) : (
          closed.map((poll) => <PollNode key={poll.id} poll={poll} />)
        )}
      </section>
    </div>
  );
}
