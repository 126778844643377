import { useState } from 'react';
import cn from 'classnames';

import { getFormattedFIL } from 'utils/numbers';
import { convertBytesToIEC } from 'utils/bytes';
import { VotesModal } from 'components/ResultsModal';

// eslint-disable-next-line css-modules/no-unused-class
import s from './s.module.css';

const formatUnit = (input, constituentGroupUnit) => {
  const secondUnit = constituentGroupUnit
    ? ` ${
        Number(input.value) === 1 &&
        constituentGroupUnit
          .charAt(constituentGroupUnit.length - 1)
          .toLowerCase() === 's'
          ? constituentGroupUnit.slice(0, constituentGroupUnit.length - 1)
          : constituentGroupUnit
      }`
    : '';
  let formattedValue;

  switch (input.unit) {
    case 'number':
      return `${input.value} ${secondUnit}`;
    case 'fil':
      formattedValue = getFormattedFIL(input.value);
      return `${formattedValue.value} ${formattedValue.unit} ${secondUnit}`;
    case 'bytes':
      formattedValue = convertBytesToIEC(input.value);
      return `${formattedValue.value} ${formattedValue.unit} ${secondUnit}`;
    default:
      return `${input.value} ${input.unit}`;
  }
};

export const Stats = ({
  values,
  className,
  options,
  constituentGroup,
  pollId,
}) => {
  const [showModal, setShowModal] = useState(false);

  values = constituentGroup.enabled ? values : null;

  const sum = values
    ? Object.values(values).reduce((acc, el) => acc + Number(el.value), 0)
    : 0;

  return (
    <>
      <div
        className={cn('block', className, {
          [s.disabled]: !constituentGroup.enabled,
        })}
        style={{
          order: constituentGroup.enabled
            ? constituentGroup.position
            : constituentGroup.position + 100,
        }}
      >
        <h3 className={cn('h3', s.title)}>
          <span>{constituentGroup.name}</span>
          {sum && constituentGroup.enabled ? (
            <button
              type="button"
              className={s.votesButton}
              onClick={() => setShowModal(true)}
            >
              Votes
            </button>
          ) : null}
        </h3>
        {options.map((label) => {
          const percent = values
            ? ((Number(values[label.id].value) / (sum || 1)) * 100).toFixed(0)
            : 0;

          return (
            <div key={label.id} className={s.row}>
              <div className={s.name}>
                <span>{label.name}</span>
                <span>
                  {values?.[label.id]
                    ? formatUnit(values[label.id], constituentGroup.unit)
                    : 0}{' '}
                  ({percent}%)
                </span>
              </div>
              <div className={cn(s.progressBar, s[label.name.toLowerCase()])}>
                <div style={{ width: `${percent}%` }} />
              </div>
            </div>
          );
        })}
      </div>
      {showModal && (
        <VotesModal
          open={showModal}
          onClose={() => setShowModal(false)}
          constituentGroup={constituentGroup}
          pollId={pollId}
          options={options}
        />
      )}
    </>
  );
};
