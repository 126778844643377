import { useState } from 'react';
import cn from 'classnames';

import { api } from 'utils/api';
import { Modal } from 'components/Modal';
import { Input, SignInput, Textarea } from 'components/Form';
import { Spinner } from 'components/Spinner';
import s from './s.module.css';
import img from './asset@x2.png';

export const VoteModal = ({ open, onClose, option, id, onSuccess }) => {
  const [values, setValues] = useState({
    address: '',
    signature: '',
  });
  const [result, setResult] = useState({
    loading: false,
    result: null,
  });

  const closeHandler = () => {
    setValues({
      address: '',
      signature: '',
    });
    setResult({
      loading: false,
      result: null,
    });
    onClose();
  };

  const changeHandler = (event) => {
    const { value, name } = event.target;

    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setResult({
      loading: true,
      result: null,
    });

    const body = JSON.stringify(Object.assign(values, { option: option.name }));

    api(`/polls/${id}/vote`, {
      method: 'POST',
      body,
    })
      .then((data) => {
        onSuccess(data);
        setResult({
          loading: false,
          result: true,
        });
      })
      .catch((err) => {
        console.error(err);
        setResult({
          loading: false,
          result: err,
        });
      });
  };

  if (!option) {
    return null;
  }

  return (
    <Modal open={open} onClose={closeHandler}>
      {result.loading ? (
        <div style={{ margin: 'auto' }}>
          <Spinner />
        </div>
      ) : (
        <>
          {result.result === null ? (
            <form className={s.modal} onSubmit={submitHandler}>
              <h3 className={cn('h3', s.modalTitle)}>
                Confirm your voting option
              </h3>
              <Input
                className={s.input}
                type="text"
                name="address"
                value={values.address}
                onChange={changeHandler}
              >
                Your address
              </Input>
              <SignInput
                className={s.signInput}
                name="sign"
                readOnly
                value={`lotus wallet sign ${values.address || 'YOUR_ADDRESS'} ${
                  option.encodedMessageToSign
                }`}
                onChange={() => null}
              />
              <Textarea
                name="signature"
                value={values.signature}
                onChange={changeHandler}
              >
                Lotus sign command output
              </Textarea>
              <div className={s.buttonsWrap}>
                <button
                  type="button"
                  onClick={closeHandler}
                  className={s.closeButton}
                >
                  Close
                </button>
                {id === '16' ? (
                  <a
                    href="https://wallet.glif.io/fip-36/"
                    className={cn('link', s.glifLink)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Vote via GLIF
                  </a>
                ) : null}
                <button type="submit" className="button">
                  Confirm vote
                </button>
              </div>
            </form>
          ) : (
            <>
              {result.result === true ? (
                <div className={s.voted}>
                  <img
                    src={img}
                    alt="Voted successful"
                    className={s.votedImage}
                  />
                  <h2 className={cn('h2', s.votedTitle)}>Congratulations!</h2>
                  <p className={s.votedText}>
                    You've successfully voted on this poll!
                  </p>
                  <button className="button" onClick={closeHandler}>
                    Back to poll page
                  </button>
                </div>
              ) : (
                <div className={s.voted}>
                  <h2 className={cn('h2', s.votedTitle)}>Error!</h2>
                  {result.result.message || result.result.error ? (
                    <div className={s.votedText}>
                      <br />
                      {result.result.message || result.result.error}
                      {result.result.status || result.result.statusCode ? (
                        <div>
                          <br />
                          Status Code:{' '}
                          {result.result.status || result.result.statusCode}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <button className="button" onClick={closeHandler}>
                    Back to poll page
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};
