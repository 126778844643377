import { Switch, Route, Redirect } from 'react-router-dom';

import { Header } from 'components/Header';
import HomePage from 'components/HomePage';
import PollPage from 'components/PollPage';
import AboutPage from 'components/AboutPage';

export default function App() {
  return (
    <>
      <Header />

      <main id="main">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/poll/:id" component={PollPage} />

          <Redirect to="/" />
        </Switch>
      </main>
    </>
  );
}
