export const LogoIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 40 40"
    style={{ width: 40, height: 40, ...style }}
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 39.9992C9 39.9992 0 30.9992 0 19.8992C0.1 8.89918 9 -0.100823 20.1 -0.000822592C31.1 0.0991774 40 8.99918 40 20.1992C39.9 31.0992 31 39.9992 20 39.9992Z"
        fill="var(--color-brand)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9002 17.6L21.3002 20.8L27.0002 21.6L26.6002 23.1L21.0002 22.3C20.6002 23.6 20.4002 25 19.9002 26.2C19.4002 27.6 18.9002 29 18.3002 30.3C17.5002 32 16.1002 33.2 14.2002 33.5C13.1002 33.7 11.9002 33.6 11.0002 32.9C10.7002 32.7 10.4002 32.3 10.4002 32C10.4002 31.6 10.6002 31.1 10.9002 30.9C11.1002 30.8 11.6002 30.9 11.9002 31C12.2002 31.3 12.5002 31.7 12.7002 32.1C13.3002 32.9 14.1002 33 14.9002 32.4C15.8002 31.6 16.3002 30.5 16.6002 29.4C17.2002 27 17.8002 24.7 18.3002 22.3V21.9L13.0002 21.1L13.2002 19.6L18.7002 20.4L19.4002 17.3L13.7002 16.4L13.9002 14.8L19.8002 15.6C20.0002 15 20.1002 14.5 20.3002 14C20.8002 12.2 21.3002 10.4 22.5002 8.79999C23.7002 7.19999 25.1002 6.19999 27.2002 6.29999C28.1002 6.29999 29.0002 6.59999 29.6002 7.29999C29.7002 7.39999 29.9002 7.59999 29.9002 7.79999C29.9002 8.19999 29.9002 8.69999 29.6002 8.99999C29.2002 9.29999 28.7002 9.19999 28.3002 8.79999C28.0002 8.49999 27.8002 8.19999 27.5002 7.89999C26.9002 7.09999 26.0002 6.99999 25.3002 7.69999C24.8002 8.19999 24.3002 8.89999 24.0002 9.59999C23.3002 11.7 22.8002 13.9 22.1002 16.1L27.6002 16.9L27.2002 18.4L21.9002 17.6Z"
        fill="var(--color-white)"
      />
    </g>
  </svg>
);

export const ClockIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58173 16.4183 4 12 4C7.58173 4 4 7.58173 4 12C4 16.4183 7.58173 20 12 20ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68628 15.3137 6 12 6C8.68628 6 6 8.68628 6 12C6 15.3137 8.68628 18 12 18Z"
      fill="currentColor"
    />
    <rect x="11" y="8" width="2" height="5" fill="currentColor" />
    <rect
      x="15.0671"
      y="14.1781"
      width="2"
      height="3.75183"
      transform="rotate(135 15.0671 14.1781)"
      fill="currentColor"
    />
  </svg>
);

export const LeftArrowIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.05029 12L11 16.9497L12.4143 15.5355L8.87872 12L12.4143 8.46446L11 7.05025L6.05029 12Z"
      fill="currentColor"
    />
    <path d="M18 11H8V13H18V11Z" fill="currentColor" />
  </svg>
);

export const CloseIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9498 8.46451L13.4143 12L16.9498 15.5356L15.5356 16.9498L12 13.4143L8.46451 16.9498L7.05029 15.5356L10.5858 12L7.05029 8.46451L8.46451 7.05029L12 10.5858L15.5356 7.05029L16.9498 8.46451Z"
      fill="currentColor"
    />
  </svg>
);

export const CopyIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 7V22H3V7H17ZM15 9H5V20H15V9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 18V3H7V8H9V5H19V16H15V18H21Z"
      fill="currentColor"
    />
  </svg>
);

export const DropdownIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8981 8C18.3277 8 18.5573 8.50596 18.2744 8.82925L12.7526 15.1399C12.3542 15.5952 11.6458 15.5952 11.2474 15.1399L5.72559 8.82925C5.44271 8.50596 5.6723 8 6.10188 8H17.8981Z"
      fill="currentColor"
    />
  </svg>
);

export const MenuIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 8V10H4V8H20ZM20 14V16H4V14H20ZM20 13V11H4V13H20Z"
      fill="currentColor"
    />
  </svg>
);

export const CopyToClipboardIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7H4V20H16V7ZM14 9H6V18H14V9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4H20V17H15V15H18V6H10V9H8V4Z"
      fill="currentColor"
    />
  </svg>
);

export const DropdownArrowIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8981 9C18.3277 9 18.5573 9.50596 18.2744 9.82925L12.7526 16.1399C12.3542 16.5952 11.6458 16.5952 11.2474 16.1399L5.72559 9.82925C5.44271 9.50596 5.6723 9 6.10188 9H17.8981Z"
      fill="currentColor"
    />
  </svg>
);

export const LoadingIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C11.1163 4 10.4 3.28366 10.4 2.4C10.4 1.51634 11.1163 0.8 12 0.8C12.8837 0.8 13.6 1.51634 13.6 2.4C13.6 3.28366 12.8837 4 12 4ZM21.6 14.4C20.2745 14.4 19.2 13.3255 19.2 12C19.2 10.6745 20.2745 9.6 21.6 9.6C22.9255 9.6 24 10.6745 24 12C24 13.3255 22.9255 14.4 21.6 14.4ZM18.8 21.6C17.2536 21.6 16 20.3464 16 18.8C16 17.2536 17.2536 16 18.8 16C20.3464 16 21.6 17.2536 21.6 18.8C21.6 20.3464 20.3464 21.6 18.8 21.6ZM4.8 18.8C4.8 19.0209 4.97909 19.2 5.2 19.2C5.42091 19.2 5.6 19.0209 5.6 18.8C5.6 18.5791 5.42091 18.4 5.2 18.4C4.97909 18.4 4.8 18.5791 4.8 18.8ZM4 5.2C4 5.86274 4.53726 6.4 5.2 6.4C5.86274 6.4 6.4 5.86274 6.4 5.2C6.4 4.53726 5.86274 4 5.2 4C4.53726 4 4 4.53726 4 5.2ZM2.4 12.8C1.95817 12.8 1.6 12.4418 1.6 12C1.6 11.5582 1.95817 11.2 2.4 11.2C2.84183 11.2 3.2 11.5582 3.2 12C3.2 12.4418 2.84183 12.8 2.4 12.8ZM16.8 5.2C16.8 6.30457 17.6954 7.2 18.8 7.2C19.9046 7.2 20.8 6.30457 20.8 5.2C20.8 4.09543 19.9046 3.2 18.8 3.2C17.6954 3.2 16.8 4.09543 16.8 5.2Z"
      fill="currentColor"
    />
  </svg>
);
