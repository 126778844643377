import { useRef, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import outy from 'outy';
import cn from 'classnames';

import { CloseIcon } from 'components/Icons';

import s from './s.module.css';

const root = document.getElementById('root');
const rootModal = document.getElementById('root-modal');

export const Modal = ({ onClose, open, children, buttonClassname }) => {
  const innerRef = useRef(null);

  const handlerClose = () => {
    onClose();
  };

  const keyboardHandler = useCallback((event) => {
    if (event.key === 'Escape') {
      handlerClose();
    }
  }, []);

  useEffect(() => {
    let outsideTap;
    if (open) {
      document.addEventListener('keydown', keyboardHandler, false);
      if (innerRef.current) {
        outsideTap = outy(innerRef.current, ['click', 'touchend'], onClose);
      }
      if (root) {
        root.setAttribute('inert', 'true');
        document.body.style.overflow = 'hidden';
      }
    }

    return () => {
      document.removeEventListener('keydown', keyboardHandler, false);
      if (outsideTap) {
        outsideTap.remove();
      }
      if (root) {
        root.removeAttribute('inert');
        document.body.style.overflow = 'visible';
      }
    };
  }, [open, onClose, keyboardHandler]);

  if (!root || !rootModal || !open) {
    return null;
  }

  return ReactDOM.createPortal(
    <section className={s.overlay}>
      <div className={s.inner} ref={innerRef}>
        <button
          onClick={handlerClose}
          type="button"
          className={cn(s.closeButton, buttonClassname)}
        >
          <CloseIcon />
        </button>
        {children}
      </div>
    </section>,
    rootModal
  );
};
