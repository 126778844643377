import { useEffect, useState } from 'react';
import cn from 'classnames';

import { api } from 'utils/api';
import { shortcutString } from 'utils/strings';
import { Modal } from 'components/Modal';
import { Spinner } from 'components/Spinner';
import s from './s.module.css';

const defaultVotesState = {
  data: null,
  loading: false,
  loaded: false,
};

export const VotesModal = ({
  open,
  onClose,
  options,
  pollId,
  constituentGroup,
}) => {
  const [votes, setVotes] = useState(defaultVotesState);

  useEffect(() => {
    setVotes({
      ...defaultVotesState,
      loading: true,
    });

    api(`/polls/${pollId}/${constituentGroup.id}/voteList`)
      .then((data) => {
        setVotes({
          data,
          loading: false,
          loaded: true,
        });
      })
      .catch((err) => {
        console.error(err);
        setVotes({
          ...defaultVotesState,
          loaded: true,
        });
      });
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose} buttonClassname={s.closeButton}>
      <>
        <div className={s.header}>
          <h3 className={cn('h3', s.title)}>{constituentGroup.name}</h3>
        </div>
        <table className={s.table}>
          <thead>
            <tr>
              <th>Address</th>
              <th>Vote</th>
              <th>IPFS</th>
            </tr>
          </thead>
          <tbody>
            {votes.data ? (
              votes.data.map(({ address, optionId, textilePath }) => (
                <tr key={address}>
                  <td>{shortcutString(address)}</td>
                  <td>
                    {options.find((option) => option.id === optionId).name}
                  </td>
                  <td>
                    {textilePath ? (
                      <a
                        href={`https://ipfs.io${textilePath}`}
                        className="link"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        View Details
                      </a>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>
                  <Spinner />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    </Modal>
  );
};
