import { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';

import { shortcutString } from 'utils/strings';
import { copyToClipboard } from 'utils/copyToClipboard';
import { CopyToClipboardIcon } from 'components/Icons';

import s from './s.module.css';

export const CopyButton = ({ string }) => {
  const [copied, setCopied] = useState(null);

  if (!string) {
    return '-';
  }

  const handlerCopy = useCallback(() => {
    copyToClipboard(string)
      .then(() => {
        setCopied(true);
      })
      .catch((e) => {
        setCopied(false);
        console.log(e);
      });
  }, [string]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setCopied(null), 2000);

    return () => clearTimeout(timeoutId);
  }, [copied, string]);

  return (
    <span className={s.wrap}>
      <button
        type="button"
        onClick={handlerCopy}
        title={`Copy to clipboard:\n${string}`}
        className={s.button}
      >
        <CopyToClipboardIcon
          className={cn(s.icon, {
            [s.copied]: copied === true,
            [s.failed]: copied === false,
          })}
        />
      </button>
      <span>{shortcutString(string)}</span>
    </span>
  );
};
