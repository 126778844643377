import DateTime from 'luxon/src/datetime.js';

const getTimeDiff = (ISOTime) => {
  return DateTime.fromISO(ISOTime)
    .diffNow(['years', 'months', 'days', 'hours'])
    .toObject();
};

export const getTimeInterval = (ISOTime) => {
  if (!DateTime.fromISO(ISOTime).isValid) {
    return 'Invalid date';
  }

  let result = '';
  const { years, months, days, hours } = getTimeDiff(ISOTime);

  if (years) {
    result = `${years} year${years === 1 ? '' : 's'}`;
    return result;
  }
  if (months) {
    result = `${months} month${months === 1 ? '' : 's'}`;
    return result;
  }
  if (days) {
    result = `${days} day${days === 1 ? '' : 's'}`;
    return result;
  }

  result = `${Math.trunc(hours)} hour${hours === 1 ? '' : 's'}`;
  return result;
};

export const getRelativeTimeInterval = (ISOTime) => {
  if (!DateTime.fromISO(ISOTime).isValid) {
    return 'Invalid date';
  }

  return DateTime.fromISO(ISOTime).toRelative();
};

export const getFormattedUTCDate = (ISOTime) => {
  if (!DateTime.fromISO(ISOTime).isValid) {
    return 'Invalid date';
  }

  const formattedDate = DateTime.fromISO(ISOTime)
    .toUTC()
    .toFormat('y-LL-dd HH:mm');
  return `${formattedDate} (UTC)`;
};
