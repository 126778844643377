import filesize from 'filesize';

export function convertBytesToIEC(bytes) {
  if (!isFinite(Number(bytes) || null)) {
    return { unit: '', value: '' };
  }

  const { value, symbol } = filesize(Number(bytes), {
    standard: 'iec',
    output: 'object',
  });

  return { unit: symbol, value };
}
