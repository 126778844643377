import cn from 'classnames';

// eslint-disable-next-line css-modules/no-unused-class
import s from './s.module.css';

export const Status = ({ status }) => {
  if (status !== 'open' && status !== 'closed') {
    return <span className={s.capitalize}>{status}</span>;
  }

  return <span className={cn(s.label, s[status])}>{status}</span>;
};
